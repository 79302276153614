<style>
    .call-to-action-container {
        container: call-to-action / size;
        background-image: url("@assets/images/cta_background.svg");
        background-size: contain;
        aspect-ratio: 1691 / 1092;
        overflow: hidden;
    }
    @container call-to-action (height) {
        .call-to-action-container > * {
            padding: 15cqh;
            font-size: 12cqh;
            line-height: 1.164;
            text-align: center;
        }
    }
</style>

<template>
    <section class="call-to-action-container">
        <div>
            <slot></slot>
        </div>
    </section>
</template>
